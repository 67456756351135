<template>
	<div class="page">
		<div class="w680">
			<topHeader></topHeader>
			<div class="top-col">今日需学习 <b>0/10</b></div>
			<!-- 卡片 -->
			<div class="word-panel">
				<!-- 单词 -->
				<div class="master">
					<span>crayon</span>
					<div class="box-showdow"></div>
				</div>
				<!-- 读音 -->
				<div class="linsten-block">
					<div class="linsten-btn">
						<img src="@/assets/static/linsten.png" mode="" />
					</div>
					<span>美 /'krelan/</span>
				</div>
				<!-- 解释 -->
				<div class="desc-item" v-for="(item, index) in wordTpye" :key="index">
					<span>{{ item.label }}</span>
					<span>{{ item.value }}</span>
				</div>
				<!-- 例图 -->
				<img class="word-img" src="@/assets/static/example.png" />
				<div class="mid-line"></div>
				<div class="s-tip">请从下列4个选择中选择正确的词义</div>
				<div class="select-list">
					<div class="select-item">
						<span>A. 苹果</span>
					</div>
					<div class="select-item error">
						<img class="select-icon" src="@/assets/static/come_on.png" />
						<span>B. 香蕉</span>
					</div>
					<div class="select-item">
						<span>A. 苹果</span>
					</div>
					<div class="select-item right">
						<img class="select-icon" src="@/assets/static/great.png" />
						<span>A. 苹果</span>
					</div>
				</div>

				<div class="btn-group">
					<img src="@/assets/static/cancel_word_btn.png" desc="消词" alt="" />
					<img src="@/assets/static/star_icon_checked.png" v-if="true" desc="已收藏" alt="" />
					<img src="@/assets/static/star_icon.png" v-else desc="未收藏" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import topHeader from "@/components/topHeader/topHeader.vue";

	export default {
		components: {
			topHeader,
		},
		data() {
			return {
				wordTpye: [{
					label: "n.",
					value: "蜡笔;彩色铅笔(或粉笔)",
				}, ],
			};
		},
		methods: {
			goBack() {
				this.$router.back();
			},
			toWordBookDetail() {
				this.$router.push({
					name: "wordBooksDetail"
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.top-col {
		width: 100%;
		height: 60px;
		padding: 0 20px;
		display: flex;
		align-items: center;
		border: 1px solid #e5e5e5;
		margin-top: 16px;
		border-radius: 12px;
		color: #222222;
		font-size: 16px;

		b {
			margin-left: 4px;
		}
	}

	.word-panel {
		border-radius: 12px;
		min-height: 400px;
		padding: 32px;
		margin-top: 20px;
		position: relative;
		border: 1px solid #e5e5e5;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 40px;

		.master {
			color: #222;
			font-size: 34px;
			font-weight: bold;
			position: relative;
			display: flex;
			word-break: break-all;

			span {
				position: relative;
				z-index: 8;
			}

			.box-showdow {
				position: absolute;
				width: 100%;
				height: 10px;
				background: #fdf0cf;
				left: 3px;
				bottom: 8px;
				border-radius: 4px;
			}
		}

		.linsten-block {
			margin-top: 7px;
			display: flex;
			align-items: center;

			span {
				font-size: 16px;
				margin-left: 12px;
				color: #666;
			}

			.linsten-btn {
				border-radius: 20px;
				border: 1px solid #e0e0e0;
				background: #fff;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				height: 24px;
				width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				&:hover {
					transform: scale(1.05);
				}

				img {
					width: 16px;
					height: 16px;
				}
			}
		}

		.desc-item {
			display: flex;
			margin-top: 16px;
			line-height: 18px;

			span:first-child {
				font-size: 16px;
				color: #999999;
			}

			span:last-child {
				font-size: 14px;
				color: #222222;
				margin-left: 12px;
			}
		}

		.word-img {
			width: 263px;
			height: 136px;
			border-radius: 12px;
			margin-top: 20px;
		}

		.mid-line {
			border-bottom: dashed 1px #dbdbdb;
			width: 263px;
			margin: 20px 0;
		}

		.s-tip {
			width: 263px;
			font-size: 14px;
			font-weight: bold;
			color: #5c3704;
		}

		.select-list {
			display: flex;
			flex-direction: column;
			width: 263px;
			margin-top: 16px;

			.select-item {
				display: flex;
				align-items: center;
				margin-bottom: 12px;
				height: 55px;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				background: #ffffff;
				border-radius: 8px;
				padding: 0 20px;
				position: relative;
				border: solid 1px #e5e5e5;
				font-weight: bold;
				cursor: pointer;

				&:hover {
					border: solid 1px #aaa;
				}
			}

			.error {
				background: #f85b59 !important;
				color: #fff !important;
			}

			.right {
				background: #51d9a5 !important;
				color: #fff !important;
			}

			.select-icon {
				height: 28px;
				position: absolute;
				right: 0;
				top: -31px;
				object-fit: contain;
			}
		}

		.btn-group {
			margin: 20px 0 0;

			img {
				cursor: pointer;
				transition: all 0.3s;

				&:hover {
					opacity: 0.8;
					transform: scale(1.05);
				}
			}
		}
	}
</style>